.content-wrapper h1 {
  text-align: center;
  font-family: "Roboto Slab", serif;
  margin-top: 25px;
  margin-bottom: 1em;
  font-weight: 700;
  font-size: 3em;
}

p.song-title a {
  color: black;
  text-decoration: underline;
}
